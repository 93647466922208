import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';
// import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirmation-token',
  templateUrl: './confirmation-token.component.html',
  styleUrls: ['./confirmation-token.component.scss']
})
export class ConfirmationTokenComponent implements OnInit {
  showProgress = false;
  token;
  status = "Approval process is going on. It will take time. Please be patient."
  constructor( private route: ActivatedRoute, 
    private router: Router, 
    private http: HttpClient,
    private authService: AuthService) { }

  ngOnInit() {
   
    this.route.queryParams.subscribe(params => {
       this.token = params['token'] || null;
     });
   

   this.showProgress = true;

   this.authService.approveToken(this.token).subscribe(
        (data:any) => {
            // this.snackBar.open("Your Company is Approved!.\n Check your email for your login details. Login and complete your setup.","OK", {
            //     duration: 5000,
            //   });
              this.showProgress = false;
              this.status = "Your Company is Approved!.\n Check your email for your login details. Login and complete your setup."
        },
        error => {
            this.showProgress = false;
            this.status = "Can't able to process request, please contact admin."

            let message;
            if(error.error.umessage != undefined){
                message = error.error.umessage;
            }else{
                message = "Can't able to process request, please contact admin."
            }
            // this.snackBar.open(message,"OK", {
            //     duration: 5000,
            //   });
        }
    );
  }

}
